@use "./config/" as *;
@forward "./main-scss/";
@forward "./partials/form-common";
.waf-breadcrumb {
    @extend %d-none;
}
.waf-account {
    .form-wrapper {
        @extend %w-100;
    }
    .email-address {
        .form-input {
            text-transform: none;
        }
    }
    .email-otp {
        .otp-title {
            @extend %mb-3;
            @extend %font-14-pm;
            @extend %neutral-800;
        }
        .otp-sub-title {
            line-height: 2rem;
            @extend %font-14-pr;
            @extend %neutral-500;
        }
        .otp-wrap {
            @extend %mb-4;
            @extend %gap-4;
            @extend %flex;
            .form-input {
                flex-basis: 4.3rem;
                padding: 0;
                @extend %text-center;
            }
        }
        .resend-otp {
            justify-content: end;
            @extend %pb-7;
            @extend %gap-3;
            @extend %flex-column;
            @extend %text-right;
            .text {
                @extend %secondary-800;
                @extend %font-14-pr;
                .otp {
                    font-weight: 700;
                    @extend %secondary-800;
                }
            }
        }
        .otp-failed {
            .form-input {
                border: .1rem solid var(--error-800);
                color: var(--error-800);
            }
        }
        .otp-success {
            .form-input {
                border: .1rem solid var(--success-800);
                color: var(--success-800);
            }
        }
        .otp-wrap {
            + .error-msg {
                display: block;
                margin-top: var(--space-3-neg);
                padding-bottom: var(--space-2);
                @extend %text-right;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-account {
        .form-data {
            padding-inline: 9rem;
        }
        .btn-create {
            width: 100%;
        }
        .form-group-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: var(--space-4);
            width: 100%;
            .form-group {
                margin-bottom: 0;
            }
        }
        .email-otp {
            order: 1;
            width: 100%;
            display: flex;
            gap: var(--space-4);
            .otp-wrap {
                gap: var(--space-3);
                .form-element {
                    flex: 1;
                    height: 4.8rem;
                }
                .form-input {
                    padding: 0;
                    height: 100%;
                }
            }
            .form-group {
                margin-bottom: 0;
            }
            .resend-otp {
                padding-bottom: 0;
            }
        }
    }
}